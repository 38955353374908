<template>
    <v-btn @click="switchLocale('en')" icon><v-icon>mdi-web</v-icon></v-btn>
</template>

<script>
export default {
    methods: {
        switchLocale(locale) {
            if (this.$i18n.locale !== locale) {
                this.$i18n.locale = locale;
                const to = this.$router.resolve({ params: { locale } }); // <--------------------
                this.$router.push(to.location); // <--------------------
            }
        }
    }
};
</script>

<style></style>
